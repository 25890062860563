<template>
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-12">WELCOME TO PRODUCVER VIEW</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
